<template>
  <v-autocomplete
    v-model="value.data[field.key]"
    :items="speciesItemsFiltered"
    item-value="value"
    item-text="name"
    outlined
    dense
    class="w-100"
  />
</template>
<script>
import opencity from "@/service/opencity";

export default {
  name: "SpeciesSelectField",
  props: {
    field: {},
    value: {}
  },
  computed: {
    speciesItems() {
      return this.getSpeciesItems(this.field.taxonomy);
    },
    speciesItemsFiltered() {
      const { genus } = this.value.data;
      if (!genus) return this.speciesItems;
      return this.speciesItems.filter(el => el.parent === genus);
    }
  },
  methods: {
    getSpeciesItems(taxonomy) {
      const tax = opencity.getTaxonomy(taxonomy);
      return tax.reduce(
        (ar, el) => [
          ...ar,
          ...el.species.map(value => ({
            name: el.name + " " + value,
            value: el.name + " " + value,
            parent: el
          }))
        ],
        []
      );
    }
  }
};
</script>
