import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{staticClass:"w-100",attrs:{"items":_vm.genera,"item-text":"name","return-object":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.name)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item.lat)}})],1)]}}]),model:{value:(_vm.value.data[_vm.field.key]),callback:function ($$v) {_vm.$set(_vm.value.data, _vm.field.key, $$v)},expression:"value.data[field.key]"}})}
var staticRenderFns = []

export { render, staticRenderFns }