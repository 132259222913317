<template>
  <v-autocomplete
    v-model="value.data[field.key]"
    :items="genera"
    item-text="name"
    return-object
    outlined
    dense
    class="w-100"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
        <v-list-item-subtitle v-text="item.lat"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import opencity from "@/service/opencity";

export default {
  name: "GeneraSelectfield",
  props: {
    field: {},
    value: {}
  },
  computed: {
    species() {
      return this.getSpeciesItems(this.field.taxonomy);
    },
    genera() {
      return this.getGeneraItems(this.field.taxonomy);
    }
  },
  methods: {
    getGeneraItems(taxonomy) {
      return opencity.getTaxonomy(taxonomy);
    },
    getSpeciesItems(taxonomy) {
      const tax = opencity.getTaxonomy(taxonomy);
      return tax.reduce(
        (ar, el) => [
          ...ar,
          ...el.species.map(value => ({
            name: el.name + " " + value,
            value: el.name + " " + value,
            parent: el
          }))
        ],
        []
      );
    }
  },
  watch: {
    "value.data.species"(val) {
      if (!val) return;
      this.value.data.genus = this.species.find(
        el => el.name === val
      )?.parent;
    }
  }
};
</script>
